var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$hasPermissions(_vm.clientSession, ["C8925_REPORT"], 1)
      ? _c(
          "div",
          [
            _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
            _c(
              "div",
              {
                staticClass: "has-text-centered has-background-primary",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "is-size-6 has-text-white",
                    staticStyle: { padding: "5px 0px" },
                  },
                  [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
                ),
              ]
            ),
            _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
              _c("div", { attrs: { id: "body-content-area" } }, [
                _c(
                  "div",
                  { class: { hidden: !_vm.isLoading } },
                  [_c("Loading")],
                  1
                ),
                _c("div", { class: { visible: _vm.isLoading } }, [
                  _c(
                    "div",
                    {
                      staticClass: "top-bar-spacer",
                      staticStyle: { height: "8px", width: "100%" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "control grid-button top-bar",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                          "margin-bottom": "1rem",
                        },
                      },
                      [
                        _c("div", { attrs: { id: "buttongroup" } }, [
                          _c("div", { staticClass: "level" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-accent",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "8925ReportingGenerate",
                                            params: {},
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(0),
                                      _c("span", [
                                        _vm._v(" Generate 8925 Reports "),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-light",
                                      attrs: {
                                        disabled: _vm.isLoadingAssetReports,
                                      },
                                      on: { click: _vm.getAssetReports },
                                    },
                                    [
                                      _c("span", { staticClass: "icon" }, [
                                        !_vm.isLoadingAssetReports
                                          ? _c("i", {
                                              staticClass: "fas fa-sync-alt",
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "fas fa-spinner fa-spin",
                                            }),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { ref: "divWrapper" },
                      [
                        _vm.maxHeight > 0
                          ? _c("Grid", {
                              style: { height: _vm.maxHeight + "px" },
                              attrs: {
                                filter: _vm.filter,
                                "data-items": _vm.institutionReports,
                                "selected-field": _vm.selectedField,
                                sortable: true,
                                sort: _vm.sort,
                                filterable: true,
                                pageable: Object.assign({}, _vm.pageable, {
                                  pageSizes: _vm.pageSizes,
                                }),
                                "page-size": _vm.pageSize,
                                skip: _vm.pagination.skip,
                                take: _vm.pagination.take,
                                total: _vm.pagination.total,
                                columns: _vm.columns,
                              },
                              on: {
                                pagechange: _vm.pageChangeHandler,
                                sortchange: _vm.sortChangeHandler,
                                filterchange: _vm.filterChangeHandler,
                                selectionchange: _vm.onSelectionChange,
                                headerselectionchange:
                                  _vm.onHeaderSelectionChange,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "downloadTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c(
                                          "td",
                                          { staticClass: "has-text-centered" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "download",
                                                attrs: {
                                                  href:
                                                    "" + props.dataItem.path,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-download",
                                                }),
                                              ]
                                            ),
                                            _vm._v("   "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "download",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.viewReport(
                                                      props.dataItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-eye",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "batchNameCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "link",
                                              attrs: {
                                                href: "javascript:void()",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.$router.push({
                                                    name: "8925ReportingBatchView",
                                                    params: {
                                                      id: props.dataItem.id,
                                                    },
                                                  })
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.dataItem.batchName.replace(
                                                      " Bank Asset Reports",
                                                      ""
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "headerCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              cursor: "pointer!important",
                                              "background-color":
                                                "#f5f5f5!important",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return function () {
                                                  if (props.dir === undefined) {
                                                    props.dir = "asc"
                                                  } else if (
                                                    props.dir === "asc"
                                                  ) {
                                                    props.dir = "desc"
                                                  } else {
                                                    props.dir = undefined
                                                  }

                                                  _vm.sortChangeHandler({
                                                    sort: [
                                                      {
                                                        field: props.field,
                                                        dir: props.dir,
                                                      },
                                                    ],
                                                  })
                                                }.apply(null, arguments)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void()",
                                                },
                                              },
                                              [
                                                props.dir === "asc" &&
                                                _vm.sort.find(function (_rec) {
                                                  return (
                                                    _rec.field === "batchName"
                                                  )
                                                })
                                                  ? _c("span", {
                                                      staticClass:
                                                        "k-icon k-i-sort-asc-sm",
                                                    })
                                                  : _vm._e(),
                                                props.dir === "desc" &&
                                                _vm.sort.find(function (_rec) {
                                                  return (
                                                    _rec.field === "batchName"
                                                  )
                                                })
                                                  ? _c("span", {
                                                      staticClass:
                                                        "k-icon k-i-sort-desc-sm",
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "black",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(props.title))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "generationCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", {}, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.dataItem.stats.inQueue
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "FailedCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", {}, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.dataItem.stats.failed
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "ReadyCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", {}, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.dataItem.stats.generated
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "ProgressCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", {}, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (
                                                  (props.dataItem.stats
                                                    .generated /
                                                    props.dataItem.stats
                                                      .scheduled) *
                                                  100
                                                ).toFixed(2)
                                              ) +
                                              "% of " +
                                              _vm._s(
                                                props.dataItem.stats.scheduled
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "reportTypeFilterSlotTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      var methods = ref.methods
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "k-filtercell" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "k-filtercell-wrapper",
                                              },
                                              [
                                                _c("input", {
                                                  staticClass: "k-textbox",
                                                  attrs: {
                                                    type: "text",
                                                    id: "" + props.field,
                                                  },
                                                  domProps: {
                                                    value: props.value,
                                                  },
                                                  on: {
                                                    input: function (ev) {
                                                      methods.change({
                                                        operator: "contains",
                                                        field: props.field,
                                                        value: ev.target.value,
                                                        syntheticEvent: ev,
                                                      })
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                907631681
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.showDeleteModal
                    ? _c(
                        "div",
                        {
                          staticClass: "modal is-active",
                          attrs: { id: "delete-modal" },
                        },
                        [
                          _c("div", {
                            staticClass: "modal-background",
                            on: {
                              click: function ($event) {
                                _vm.showDeleteModal = false
                              },
                            },
                          }),
                          _c("div", { staticClass: "modal-card" }, [
                            _c(
                              "header",
                              { staticClass: "modal-card-head has-bg-danger" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-card-title has-bg-danger",
                                  },
                                  [_vm._v("Confirm Delete")]
                                ),
                                _c("a", {
                                  staticClass: "delete",
                                  attrs: { "aria-label": "close" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c("section", { staticClass: "modal-card-body" }, [
                              _vm._m(1),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      { name: "focus", rawName: "v-focus" },
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.confirmationText,
                                        expression: "confirmationText",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "type `delete` and then click confirm",
                                    },
                                    domProps: { value: _vm.confirmationText },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.deleteIfConfirmed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.confirmationText =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("footer", { staticClass: "modal-card-foot" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-danger",
                                  attrs: {
                                    disabled: _vm.confirmationText !== "delete",
                                  },
                                  on: { click: _vm.deleteIfConfirmed },
                                },
                                [_vm._m(2), _c("span", [_vm._v("Confirm")])]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
            _vm._m(3),
            _c("center", [
              _c("b", [
                _vm._v("You Lack The Permissions Required To View This Page"),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "has-text-centered has-background-primary",
        staticStyle: { "margin-bottom": "20px" },
      },
      [
        _c(
          "h1",
          {
            staticClass: "is-size-6 has-text-white",
            staticStyle: { padding: "5px 0px" },
          },
          [_vm._v("No Permission")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }